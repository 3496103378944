import PropTypes from 'prop-types';
import { createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const HTML_MARKUP_OPTIONS = { div: 'div', a: 'a', button: 'button' };

const DropdownItem = forwardRef(
  (
    { as = 'div', ariaLabel, divider = false, className, children, ...rest },
    ref
  ) =>
    createElement(
      HTML_MARKUP_OPTIONS[as] || HTML_MARKUP_OPTIONS.div,
      {
        ref,
        'aria-label': ariaLabel,
        className: twMerge(
          'w-full flex cursor-pointer select-none items-center whitespace-nowrap px-4 py-2 text-sm text-neutral-low-300 hover:bg-neutral-high-200 dark:bg-neutral-low-500 dark:text-neutral-high-300 dark:hover:bg-neutral-low-400 lg:text-base',
          divider &&
            'border-t border-solid border-neutral-high-300 dark:border-neutral-low-300',
          className
        ),
        role: 'menuitem',
        ...rest,
      },
      children
    )
);

DropdownItem.displayName = 'DropdownItem';

DropdownItem.propTypes = {
  /**
   * Polymorphic changes the rendered html tag
   */
  as: PropTypes.oneOf(Object.values(HTML_MARKUP_OPTIONS)),
  /**
   * `aria-label` to be used
   */
  ariaLabel: PropTypes.string,
  /**
   * List text to be rendered as children
   */
  children: PropTypes.node.isRequired,
  /**
   * Whether item uses a divider
   */
  divider: PropTypes.bool,
  /**
   * Callback function on click
   */
  onClick: PropTypes.func,
};

export default DropdownItem;
